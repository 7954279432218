import {Doughnut} from "vue-chartjs";
import exportedVars from '@/assets/scss/_theme-default.scss'
export default {
    extends: Doughnut,
    name: "DoughnutChart",
    props: ["data","type"],
    computed: {
        chartData() {
            return this.data
        },
        chartColours(){
            if (this.type==='applications'){
                return Object.values(exportedVars)
            }
            return [
                Object.values(exportedVars)[0],
                Object.values(exportedVars)[3],
                Object.values(exportedVars)[1],
                Object.values(exportedVars)[2],
            ]
        },
    },
    methods: {
        makeChart() {
            console.log(exportedVars)
            let chartData = {
                labels: [],
                datasets: [
                    {
                        label: "Views by Category",
                        data: [],
                        backgroundColor: this.chartColours
                    }
                ]
            };

            for (let category in this.data) {
                // noinspection JSUnfilteredForInLoop
                chartData.labels.push(category);
                // noinspection JSUnfilteredForInLoop
                chartData.datasets[0].data.push(this.data[category]);
            }

            this.renderChart(chartData, {
                responsive: true,
                maintainAspectRatio: true,
                legend: {display: false},
            });
        }
    },
    watch: {
        chartData() {
            this.makeChart()
        }
    },
    mounted() {
        this.makeChart()

    }
};
