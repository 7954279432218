<template>
  <section class="section tile is-ancestor">
    <div class="tile is-6 is-parent is-vertical">
      <div class="tile is-child">
        <div v-if="hasPermission('view applications')" class="column is-12">
          <card-component
              :header-icon="$tc('icons.applications')"
              :title="'Applications'"
          >
            <div class="columns pb-5 is-multiline is-centered">
              <div class="column is-6">
                <DoughnutChart ref="chart" v-if="loaded" :type="'applications'" :data="displayedApplicationsData"/>
                <b-skeleton
                    position="is-centered"
                    :active="!loaded"
                    circle
                    width="150px"
                    height="150px"
                ></b-skeleton>
              </div>
              <div class="column is-6 has-text-right">
                <b-field type="is-primary">
                  <b-select :loading="fetching" v-model="$store.state.campus.enrolment_filter_year"
                            class="is-borderless is-light has-text-primary"
                            placeholder="Select a year">
                    <option
                        v-for="year of $store.getters['dates/years']"
                        :key="year + 'year'"
                        :value="year"
                    >{{ year }}
                    </option>
                  </b-select>
                </b-field>
                <b-field>
                  <b-button
                      tag="router-link"
                      to="/applications"
                      v-if="hasPermission('create applications')"
                      :icon-left="$tc('icons.create')"
                      type="is-light"
                      class="has-drop-shadow admin-dashboard-button"
                  >
                    Add New
                  </b-button>
                </b-field>
                <div class="has-text-left">
                  <b-skeleton
                      :active="!loaded"
                      size="is-large"
                      :count="3"
                  ></b-skeleton>

                  <ul v-if="loaded" class="chart-key applications-key">
                    <li
                        v-for="(value, type, index) in applicationsByStatus"
                        :key="value + 'bob' + index"
                        :class="type.replace(/\s+/g, '')"
                    >
                      <div class="columns is-mobile">
                        <div class="column is-1">
                          <b-icon :icon="$tc('icons.circle')"/>
                        </div>
                        <div class="column is-capitalized">
                          {{ type }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column is-12">
                <div v-if="displayedApplicationsData" class="columns is-multiline pt-5">
                  <PerfectScrollbarWrapper>

                  <div class="column is-12">
                    <div class="columns">
                      <div v-for="status in applicationStatuses" class="column is-narrow" :key="status.id">
                        <p>
                          <a @click.prevent="applicationClick(status.id)"
                             v-if="loaded"
                             class="is-size-2 has-text-primary"
                          >{{ displayedApplicationsData[status.name] }}</a
                          >
                          <b-skeleton
                              size="is-large"
                              :active="!loaded"
                              :count="1"
                          ></b-skeleton>

                          {{ status.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="applicationsByCampus" class="column is-12">
                      <b-table
                          class="margin-top is-fullwidth"
                          :data="applicationsByCampus"
                          :loading="!loaded"
                          :striped="true"
                          :hoverable="true"
                          :selected="selectedCampus"
                          :row-class="(row, index) =>  (selectedCampus!==null?row.id == selectedCampus.id:false) && 'is-selected'"
                          @click="checkUnselect"
                      >
                        <template #empty>
                          <div class="has-text-centered">No Data</div>
                        </template>
                        <b-table-column
                            v-slot="props"
                            label="ID"
                            field="id"
                            sortable
                            narrowed
                            centered
                            numeric
                        >{{ props.row.id }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            label="Campus"
                            field="name"
                            sortable
                        >{{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            v-for="status in applicationStatuses"
                            :key="status.id"
                            v-slot="props"
                            :label="status.name"
                            :field="status.name"
                            sortable
                        >{{ props.row[status.name] }}
                        </b-table-column>

                      </b-table>

                  </div>
                  </PerfectScrollbarWrapper>

                </div>
                <div v-else>
                  <p class="is-size-4">No data available for selected year</p>
                </div>
              </div>
            </div>


          </card-component>
        </div>

        <div v-if="hasPermission('view learners')" class="column is-12">
          <card-component
              :header-icon="$tc('icons.learners')"
              :title="$tc('Learner', 2)"
          >
            <div class="columns pb-5 is-centered">
              <div class="column">
                <p>
                        <span
                            v-if="loaded"
                            class="is-size-2 has-text-primary"
                        >{{ dashboard.learner_count }}</span>
                  Total {{ $tc('Learner', 2) }}
                </p>
                <b-skeleton
                    size="is-large"
                    :active="!loaded"
                    :count="2"
                ></b-skeleton>
              </div>
              <div class="column has-text-right">
                <b-button
                    v-if="hasPermission('create learners')"
                    :icon-left="$tc('icons.create')"
                    type="is-light"
                    class="has-drop-shadow admin-dashboard-button"
                    @click="createLearner()"
                >
                  Add New
                </b-button>
              </div>
            </div>
          </card-component>
        </div>
      </div>

    </div>
    <div class="tile is-6 is-parent is-vertical">

      <div class="tile is-child">
        <div v-if="hasPermission('view enrolments')" class="column is-12">
          <card-component
              style="height:100%"
              :header-icon="$tc('icons.enrolments')"
              :title="'Enrolments'"
          >
            <div class="columns pb-5 is-multiline is-centered">
              <div class="column is-6">
                <DoughnutChart ref="chart" v-if="loaded" :data="displayedEnrolmentsData"/>
                <b-skeleton
                    position="is-centered"
                    :active="!loaded"
                    circle
                    width="150px"
                    height="150px"
                ></b-skeleton>
              </div>
              <div class="column is-6 has-text-right">
                <b-field type="is-primary">
                  <b-select :loading="fetching" v-model="$store.state.campus.enrolment_filter_year"
                            class="is-borderless is-light has-text-primary"
                            placeholder="Select a year">
                    <option
                        v-for="year of $store.getters['dates/years']"
                        :key="year + 'year'"
                        :value="year"
                    >{{ year }}
                    </option>
                  </b-select>
                </b-field>
                <b-field type="is-primary">
                  <b-select :loading="fetching" v-model="$store.state.campus.enrolment_type"
                            class="is-borderless is-light has-text-primary"
                            placeholder="Select an enrolment type">
                    <option
                        v-for="type of $store.state.profile.student_types"
                        :key="type.id"
                        :value="type.id"
                    >{{ type.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field>
                  <b-button
                      tag="router-link"
                      to="/enrolments"
                      v-if="hasPermission('create enrolments')"
                      :icon-left="$tc('icons.create')"
                      type="is-light"
                      class="has-drop-shadow admin-dashboard-button"
                  >
                    Add New
                  </b-button>
                </b-field>
                <div class="has-text-left">
                  <b-skeleton
                      :active="!loaded"
                      size="is-large"
                      :count="3"
                  ></b-skeleton>

                  <ul v-if="loaded" class="chart-key enrolments-key">
                    <li
                        v-for="(value, type, index) in enrolmentsByStatus"
                        :class="type.replace(/\s+/g, '')"
                        :key="value + 'bob' + index"
                    >
                      <div class="columns is-mobile">
                        <div class="column is-1">
                          <b-icon :icon="$tc('icons.circle')"/>
                        </div>
                        <div class="column">
                          {{ type }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column is-12">
                <div v-if="displayedEnrolmentsData" class="columns is-multiline pt-5">
                  <PerfectScrollbarWrapper>

                  <div class="column is-12">
                    <div class="columns">
                      <div v-for="status in enrolmentStatuses" class="column is-narrow " :key="status.id">
                        <p>
                          <a @click.prevent="enrolmentClick(status.id)"
                             v-if="loaded"
                             class="is-size-2 has-text-primary"
                          >{{ displayedEnrolmentsData[status.name] }}</a
                          >
                          <b-skeleton
                              size="is-large"
                              :active="!loaded"
                              :count="1"
                          ></b-skeleton>

                          {{ status.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="enrolmentsByCampus" class="column is-12">
                      <b-table
                          class="margin-top is-fullwidth"
                          :data="enrolmentsByCampus"
                          :loading="!loaded"
                          :striped="true"
                          :hoverable="true"
                          :selected="selectedCampus"
                          :row-class="(row, index) =>  (selectedCampus!==null?row.id == selectedCampus.id:false) && 'is-selected'"
                          @click="checkUnselect"
                      >
                        <template #empty>
                          <div class="has-text-centered">No Data</div>
                        </template>
                        <b-table-column
                            v-slot="props"
                            label="ID"
                            field="id"
                            sortable
                            narrowed
                            centered
                            numeric
                        >{{ props.row.id }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            label="Campus"
                            field="name"
                            sortable
                        >{{ props.row.name }}
                        </b-table-column>
                        <b-table-column
                            v-for="status in enrolmentStatuses"
                            :key="status.id"
                            v-slot="props"
                            :label="status.name"
                            :field="status.name"
                            sortable
                        >{{ props.row[status.name] }}
                        </b-table-column>

                      </b-table>

                  </div>
                  </PerfectScrollbarWrapper>

                </div>
                <div v-else>
                  <p class="is-size-4">No data available for selected year</p>
                </div>
              </div>
            </div>
          </card-component>
        </div>

        <!--        <div v-if="hasPermission('view announcements')&&$store.state.user.id" class="column is-12">-->
        <!--          <card-component-->
        <!--              :header-icon="$tc('icons.announcements')"-->
        <!--              :title="'Announcements'"-->
        <!--          >-->
        <!--            <AnnouncementsPanel :can-create="hasPermission('create announcements')"-->
        <!--                                :can-delete="hasPermission('delete announcements')"-->
        <!--                                :can-edit="hasPermission('edit announcements')"></AnnouncementsPanel>-->
        <!--          </card-component>-->
        <!--        </div>-->
      </div>

    </div>
  </section>
</template>

<script>
import CardComponent from "@/components/layout/CardComponent";
import UserCreateForm from "@/components/users/UserCreateForm";
import Campus from "@/models/Campus";
import Dashboard from "@/models/Dashboard";
import DoughnutChart from "@/components/charts/Doughnut";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import {mapState} from 'vuex'
import ApplicationStatus from "@/models/ApplicationStatus";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
// import AnnouncementsPanel from "@/components/announcements/AnnouncementsPanel";

export default {
  name: "dashboard.index",
  components: {
    // AnnouncementsPanel,
    DoughnutChart,
    CardComponent,
    PerfectScrollbarWrapper
  },
  data() {
    return {
      fetching: false,
      loaded: false,
      selectedCampus: null,
      selectedEnrolmentData: null,
      selectedApplicationData: null,
    };
  },
  mounted() {
    Promise.all([this.getDashboard(), EnrolmentStatus.FetchAll(), ApplicationStatus.FetchAll()]).then(() => {
      this.$nextTick(() => {
        this.loaded = true;
      })
    })

  },
  watch: {
    enrolment_filter_year() {
      this.selectedCampus = null
      this.getDashboard()
    },    enrolment_type() {
      this.getDashboard()
    },

  },
  computed: {
    ...mapState('campus', ['enrolment_filter_year','enrolment_type']),

    displayedEnrolmentsData() {
      if (this.selectedCampus) {
        let data = JSON.parse(JSON.stringify(this.enrolmentsByCampus.find(enrolments => enrolments.id == this.selectedCampus.id)))
        delete data.id
        delete data.name
        return data
      }
      return this.enrolmentsByStatus
    }, displayedApplicationsData() {
      if (this.selectedCampus) {
        let data = JSON.parse(JSON.stringify(this.applicationsByCampus.find(applications => applications.id == this.selectedCampus.id)))
        delete data.id
        delete data.name
        return data
      }
      return this.applicationsByStatus
    },
    enrolmentsByStatus() {
      if (this.loaded) {
        if (this.dashboard.enrolments_by_status_count !== null) {
          let data = JSON.parse(JSON.stringify(this.dashboard.enrolments_by_status_count))

          if (EnrolmentStatus.query().whereId(parseInt(Object.keys(data)[0])).exists()) {

            for (let status in data) {
              delete Object.assign(data, {[EnrolmentStatus.find(status).name]: data[status]})[status]

            }
            return data
          } else {
            return null
          }
        } else {
          return null
        }
      }
      return null
    }, applicationsByStatus() {
      if (this.loaded) {
        if (this.dashboard.applications_by_status_count !== null) {

          let data = JSON.parse(JSON.stringify(this.dashboard.applications_by_status_count))

          if (ApplicationStatus.query().whereId(parseInt(Object.keys(data)[0])).exists()) {

            for (let status in data) {
              delete Object.assign(data, {[ApplicationStatus.find(status).name]: data[status]})[status]

            }
            return data
          } else {
            return null
          }
        } else {
          return null
        }
      }
      return null
    },
    dashboard() {
      return Dashboard.query().first();
    },
    enrolmentStatuses() {

      return [
          EnrolmentStatus.query().where('name','Enrolled').first(),
          EnrolmentStatus.query().where('name','Pending').first(),
          EnrolmentStatus.query().where('name','Withdrawn').first(),
          EnrolmentStatus.query().where('name','Graduated').first(),

      ]
    }, applicationStatuses() {
      return [
        ApplicationStatus.query().where('name','Completed').first(),
        ApplicationStatus.query().where('name','In progress').first(),
        ApplicationStatus.query().where('name','Waiting list').first(),
        ApplicationStatus.query().where('name','Withdrawn').first(),

      ]
    },
    campuses() {
      return Campus.query().get();
    },
    enrolmentsByCampus() {
      if (this.loaded) {
        if (this.dashboard.enrolments_by_campus_by_status_count !== null) {
          if (Object.keys(this.dashboard.enrolments_by_campus_by_status_count).length > 0) {
            let data = JSON.parse(JSON.stringify(this.dashboard.enrolments_by_campus_by_status_count))
            for (let campus in data) {
              for (let status in data[campus]) {
                data[campus].name = Campus.find(campus).name
                data[campus].id = campus
                delete Object.assign(data[campus], {[EnrolmentStatus.find(status).name]: data[campus][status]})[status]
              }
              delete Object.assign(data, {[Campus.find(campus).name]: data[campus]})[campus]
            }
            return Object.keys(data).map(i => data[i])
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }, applicationsByCampus() {
      if (this.loaded) {
        if (this.dashboard.applications_by_campus_by_status_count !== null) {
          if (Object.keys(this.dashboard.applications_by_campus_by_status_count).length > 1) {
            let data = JSON.parse(JSON.stringify(this.dashboard.applications_by_campus_by_status_count))
            for (let campus in data) {
              for (let status in data[campus]) {
                data[campus].name = Campus.find(campus).name
                data[campus].id = campus
                delete Object.assign(data[campus], {[ApplicationStatus.find(status).name]: data[campus][status]})[status]
              }
              delete Object.assign(data, {[Campus.find(campus).name]: data[campus]})[campus]
            }
            return Object.keys(data).map(i => data[i])
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  methods: {
    enrolmentClick(id) {
      this.$store.dispatch('campus/selectEnrolmentFilterStatusId', id).then(() => {
        this.$router.push('enrolments')
      })
    }, applicationClick(id) {
      this.$store.dispatch('campus/selectEnrolmentFilterStatusId', id).then(() => {
        this.$router.push('applications')
      })
    },

    getDashboard() {
      this.fetching = true
      return Dashboard.Fetch({year: this.enrolment_filter_year, enrolment_type:this.enrolment_type}).then(() => {
        this.fetching = false
      })
    },
    checkUnselect(row) {
      if (this.campuses.length > 1) {
        this.$store.dispatch('campus/selectCampus', row.id)
      }
      if (this.selectedCampus) {
        if (row.id === this.selectedCampus.id) {
          this.selectedCampus = null
        } else {
          this.selectedCampus = row
        }
      } else {
        this.selectedCampus = row
      }
    },
    createLearner() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          defaultRole: 'learner',
          inModal: true,
          edit: false,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit learners"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete learners"
          ),
          canCreate: !!this.$store.getters["entities/user-permissions/find"](
              "edit learners"
          ),
          campuses: this.campuses,
          type: "Learner",
        },
        component: UserCreateForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.$router.push(`/learners/edit/${user.id}`);
          },
        },
      });
    },
    createGuardian() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          defaultRole: 'guardian',
          inModal: true,
          edit: false,
          campuses: this.campuses,
          type: "Guardian",
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit guardians"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete guardians"
          ),
          canCreate: !!this.$store.getters["entities/user-permissions/find"](
              "edit guardians"
          ),
        },
        component: UserCreateForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.$router.push(`/guardians/edit/${user.id}`);
          },
        },
      });
    },
    createStaff() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campuses: this.campuses,
          type: "Staff Member",
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete users"
          ),
          canCreate: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
        },
        component: UserCreateForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.$router.push(`/staff/edit/${user.id}`);
          },
        },
      });
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    hasPermission(permission) {
      return !!this.$store.getters["entities/user-permissions/find"](permission);
    },
  },
};
</script>

<style scoped>
</style>
